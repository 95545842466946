document.addEventListener("turbolinks:load", function () {


    // ヘッダーメニュー
    $(function () {
        $('.account_info').on("click", function () {
            if ($('.account_nav').hasClass('active')) {
                $('.account_nav').removeClass('active');
            } else {
                $('.account_nav').addClass('active');
            }
        });
    });
    // メニュー開閉
    $(function () {
        $('#openning').on("click", function () {
            if ($('.left_menu').hasClass('active')) {
                $('.left_menu').removeClass('active');
                $('.main_wrapper').removeClass('active');
            } else {
                $('.left_menu').addClass('active');
                $('.main_wrapper').addClass('active');
            }
        });
    });

    // 案件リスト開閉
    $(function () {
        $('#open_matter').on("click", function () {
            if ($('.table_content.matter').hasClass('active')) {
                $('.table_content.matter').removeClass('active');
            } else {
                $('.table_content.matter').addClass('active');
            }
        });
    });

    // ハンバーガーメニュー開閉
    $(function () {
        $('.humberger_btn').on("click", function () {
            if ($('.left_menu').hasClass('active')) {
                $('.left_menu').removeClass('active');
                $('.main_wrapper').removeClass('active');
            } else {
                $('.left_menu').addClass('active');
                $('.main_wrapper').addClass('active');
            }
            if ($('.menu-trigger').hasClass('active')) {
                $('.menu-trigger').removeClass('active');
            } else {
                $('.menu-trigger').addClass('active');
            }
        });
    });

    // サイドメニューアコーディオン
    $(function () {
        //.accordion_oneの中の.accordion_headerがクリックされたら
        $('.s_02 .accordion_one .accordion_header').click(function () {
            //クリックされた.accordion_oneの中の.accordion_headerに隣接する.accordion_innerが開いたり閉じたりする。
            $(this).next('.accordion_inner').slideToggle();
            $(this).toggleClass("open");
            //クリックされた.accordion_oneの中の.accordion_header以外の.accordion_oneの中の.accordion_headerに隣接する.accordion_oneの中の.accordion_innerを閉じる
            $('.s_02 .accordion_one .accordion_header').not($(this)).next('.accordion_one .accordion_inner').slideUp();
            $('.s_02 .accordion_one .accordion_header').not($(this)).removeClass("open");
        });
    });
})

//tableセルにまとめてCSSリンクを貼るためのJS
jQuery(function ($) {
    $('.table_tr[data-href]').addClass('clickable')
        .click(function (e) {
            if (!$(e.target).is('a')) {
                window.location = $(e.target).closest('tr').data('href');
            }
        });

});

// 顧客選択
document.addEventListener("turbolinks:load", function () {
    jQuery(function ($) {
        $('#form_human_cost_collection_human_costs_attributes_0_client_id').change(function () {
            var client_id = $("#form_human_cost_collection_human_costs_attributes_0_client_id").val();
            $.get("client_select.js?client_id=" + client_id);
        });
        $('#form_human_cost_collection_human_costs_attributes_1_client_id').change(function () {
            var client_id2 = $("#form_human_cost_collection_human_costs_attributes_1_client_id").val();
            $.get("client_select2.js?client_id=" + client_id2);
        });
        $('#form_human_cost_collection_human_costs_attributes_2_client_id').change(function () {
            var client_id3 = $("#form_human_cost_collection_human_costs_attributes_2_client_id").val();
            $.get("client_select3.js?client_id=" + client_id3);
        });
        $('#form_human_cost_collection_human_costs_attributes_3_client_id').change(function () {
            var client_id4 = $("#form_human_cost_collection_human_costs_attributes_3_client_id").val();
            $.get("client_select4.js?client_id=" + client_id4);
        });
        $('#form_human_cost_collection_human_costs_attributes_4_client_id').change(function () {
            var client_id5 = $("#form_human_cost_collection_human_costs_attributes_4_client_id").val();
            $.get("client_select5.js?client_id=" + client_id5);
        });
    });
})
// エクセルダウンロード用のボタンJS
$(function () {
    // URLの変更(ページ遷移)
    $(document).on('click', '#xlsx_button', function () {
        url = location.protocol + location.pathname + ".xlsx" + location.search
        location.href = url
    });

});





